<template>
	<div class="error">
		<picture class="error__background">
            <source media="(min-width:1440px)" :srcset="require('@/assets/404_1440.png')">
            <source media="(min-width:992px)" :srcset="require('@/assets/404_992.png')">
            <source media="(min-width:768px)" :srcset="require('@/assets/404_768.png')">
            <source media="(min-width:320px)" :srcset="require('@/assets/404_320.png')">
            <img :src="require('@/assets/404_1440.png')" alt="Easy Dating">
        </picture>

		<div class="error__text">Don't  worry!</div>

		<div class="error__wrapper">
			<div class="error__title">404</div>
			<div class="error__subtitle">ERROR</div>
			<div class="error__caption">Page not found...</div>

			<router-link to="/" class="button">
				Back to home page
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'page-error'
};
</script>

<style lang="scss">
	.error {
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-direction: column;
		width: 100%;
		min-height: 100vh;
		padding: 0 30px;
		text-align: center;
		color: $white;
		overflow: hidden;

		@include lg {
			justify-content: center;
			align-items: flex-end;
			height: 100%;
		}

		&__text {
			z-index: 2;
			position: relative;
			color: $white;
			font-weight: 700;

			@include xs {
				font-size: 70px;
				line-height: 54px;
				text-align: center;
				margin-bottom: 13px;
			}

			@include sm {
				margin: 160px 20px auto auto;
				font-size: 87px;
				line-height: 71px;
				max-width: 260px;
				text-align: left;
			}

			@include md {
				margin: 160px 15px auto auto;
				font-size: 110px;
				line-height: 89px;
				max-width: 320px;
				text-align: left;
			}

			@include lg {
				position: absolute;
				bottom: 50px;
				left: 136px;
				font-size: 127px;
				line-height: 211px;
			}
		}

		&__background {
			z-index: -1;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: left top;
			}
		}

		&__title {
			z-index: 2;
			position: relative;
			font-weight: bold;

			@include xs {
				font-size: 125px;
				line-height: 208px;
				margin-bottom: -65px;
			}

			@include sm {
				font-size: 178px;
				line-height: 296px;
				margin-bottom: -85px;
			}

			@include md {
				font-size: 178px;
				line-height: 296px;
				margin-bottom: -65px;
			}

			@include lg {
				font-size: 212px;
				line-height: 352px;
				margin-bottom: -115px;
			}
		}

		&__subtitle {
			z-index: 2;
			position: relative;
			margin: 0 0 9px 0;
			font-weight: 800;

			@include xs {
				font-size: 40px;
				line-height: 66px;
			}

			@include sm {
				font-size: 62px;
				line-height: 103px;
			}

			@include md {
				font-size: 62px;
				line-height: 103px;
			}

			@include lg {
				font-size: 80px;
				line-height: 133px;
			}
		}

		&__caption {
			z-index: 2;
			position: relative;
			margin-bottom: 15px;
			line-height: 1;

			@media (max-width: 767px) {
				font-size: 25px;
				line-height: 42px;
			}

			@media (min-width: 768px) {
				font-size: 30px;
				line-height: 50px;
			}

			@include lg {
				font-size: 40px;
				line-height: 66px;
			}
		}

		&__wrapper {
			position: relative;
			width: 100%;

			@include xs {
				padding: 0 0 80px 0;				
			}

			@include sm {
				padding: 0 0 86px 0;
			}

			@include md {
				padding: 0 0 70px 0;
			}

			@include lg {
				width: 50%;
				height: 100%;
			}

			&::before {
				z-index: -1;
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				width: 612px;
				height: 612px;
				border-radius: 100%;
				background: linear-gradient(280.18deg, rgba(128, 118, 254, 0) -19.57%, rgba(128, 118, 254, 0) 15.98%, rgba(81, 67, 254, 0.37) 124.32%);
				transform: translateX(-50%);

				@include lg {
					top: 50%;
					width: 1067px;
					height: 1067px;
					transform: translate(-50%, -40%);
				}
			}
		}

		.button {
			display: block;
			height: 70px;
			max-width: 466px;
			margin: 0 auto;

			@include xs {
				font-size: 25px;
				line-height: 42px;
			}

			@media (min-width: 768px) {
				font-size: 30px;
				line-height: 50px;
			}
		}
	}
</style>
